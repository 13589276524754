import WolfSectionColor from '@components/ui/WolfSectionColor';
import { BoltOutlined, GroupWorkOutlined, WarningOutlined } from '@mui/icons-material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Box, Typography } from '@mui/material';
import { isColorDark } from '@utils/isColorDark';
import React from 'react';
import { Handle, Position } from 'reactflow';

interface TriggerNodeProps {
  data: {
    label: string;
    preLabel?: string;
    color: string;
    triggerType: string;
    backgroundColor?: string;
  };
}

export const TriggerNode: React.FC<TriggerNodeProps> = ({ data }) => {
  const isProcess = data.triggerType === 'process';
  const dark = isColorDark(data.backgroundColor || '#FFFFFF');

  return (
    <div
      className={
        'shadow-lg h-10 w-72 flex items-center p-4 rounded-3xl border border-solid border-primary ' +
        (isProcess ? 'h-14' : 'h-10')
      }
      style={{ backgroundColor: isProcess ? data.backgroundColor + '55' : 'white' }}>
      <Handle type="source" id="right" position={Position.Right} />
      <Handle type="source" id="bottom" position={Position.Bottom} />

      <Box className="flex items-center gap-2 w-full">
        {data.triggerType === 'CALENDAR' && (
          <Box className="flex items-center justify-center p-2">
            <CalendarTodayIcon sx={{ fontSize: 20, color: '#4B5563' }} />
          </Box>
        )}
        {data.triggerType === 'EVENT' && (
          <Box className="flex items-center justify-center p-2">
            <BoltOutlined sx={{ fontSize: 20, color: '#4B5563' }} />
          </Box>
        )}
        {data.triggerType === 'warning' && (
          <Box>
            <WarningOutlined sx={{ fontSize: 20, color: '#FFA500' }} />
          </Box>
        )}{' '}
        {data.triggerType === 'process' && (
          <Box className="w-8 h-10 rounded-full flex items-center justify-center mr-2">
            <WolfSectionColor icon={<GroupWorkOutlined />} color={data.color || '#000000'} />
          </Box>
        )}
        <Box className="flex flex-col gap-1">
          <Box className="flex flex-wrap items-center">
            {data.preLabel && isProcess && (
              <Typography
                className={`w-100 text-xs ${isProcess && dark ? 'text-white' : 'text-black'}`}
                component="span"
                title={data?.preLabel}>
                {data?.preLabel?.slice(0, 20) + (data?.preLabel?.length > 20 ? '...' : '') + ' - '}
              </Typography>
            )}
            <Typography
              className={`!font-bold ${isProcess && dark ? 'text-white' : 'text-black'}`}
              component="span"
              title={data.label}>
              {data.label.slice(0, 20) + (data.label.length > 20 ? '...' : '')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export const AuxNodeTriggerLegend: React.FC<{
  data: {
    label: string;
    preLabel?: string;
    color: string;
    triggerType?: string;
    backgroundColor?: string;
  };
}> = ({ data }) => {
  const isProcess = data.triggerType === 'process';

  return (
    <Box
      className={
        'shadow-lg h-10 w-72 flex items-center p-4 rounded-3xl border border-solid border-primary ' +
        (isProcess ? 'h-14' : 'h-10')
      }
      style={{ backgroundColor: isProcess ? data.backgroundColor + '55' : 'white' }}>
      <Box className="flex items-center gap-2 w-full">
        {data.triggerType === 'CALENDAR' && (
          <Box className="flex items-center justify-center p-2">
            <CalendarTodayIcon sx={{ fontSize: 20, color: '#4B5563' }} />
          </Box>
        )}
        {data.triggerType === 'EVENT' && (
          <Box className="flex items-center justify-center p-2">
            <BoltOutlined sx={{ fontSize: 20, color: '#4B5563' }} />
          </Box>
        )}
        {data.triggerType === 'warning' && (
          <Box>
            <WarningOutlined sx={{ fontSize: 20, color: '#FFA500' }} />
          </Box>
        )}
        {data.triggerType === 'process' && (
          <Box className="w-8 h-10 rounded-full flex items-center justify-center mr-2">
            <WolfSectionColor icon={<GroupWorkOutlined />} color={data.color || '#000000'} />
          </Box>
        )}

        <Box className="flex flex-col gap-1">
          <Box className="flex flex-col gap-1">
            <Box className="flex flex-wrap items-center">
              {data.preLabel && isProcess && (
                <Typography className={`w-100 text-xs ${'text-black'}`} component="span" title={data?.preLabel}>
                  {data?.preLabel?.slice(0, 20) + (data?.preLabel?.length > 20 ? '...' : '') + ' - '}
                </Typography>
              )}
              <Typography className={`!font-bold ${'text-black'}`} component="span" title={data.label}>
                {data.label.slice(0, 20) + (data.label.length > 20 ? '...' : '')}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TriggerNode;
