import { CalendarMonth, Email, Schedule } from '@mui/icons-material';
import { memo } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';

const TimingNode = ({ data }: NodeProps) => {
  const getIcon = () => {
    switch (data.timingType.toLowerCase()) {
      case 'email':
        return <Email sx={{ fontSize: 24 }} />;
      case 'calendar':
        return <CalendarMonth sx={{ fontSize: 24 }} />;
      default:
        return <Schedule sx={{ fontSize: 24 }} />;
    }
  };

  return (
    <div
      style={{
        background: 'white',
        padding: '2px',
        borderRadius: '50%',
        fontSize: '12px',
        border: '1px solid #ccc',
        width: '50px',
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      title={data.label + ': ' + data.preLabel}>
      <Handle type="target" id="left" position={Position.Left} />
      {getIcon()}
      <Handle type="source" id="right" position={Position.Right} />
    </div>
  );
};

export default memo(TimingNode);
