import { useTranslation } from '@hooks/useTranslation';
import { CloseOutlined } from '@mui/icons-material';
import { Box, Divider, Fade, IconButton, Modal, Typography } from '@mui/material';
import WolfButton from './ui/WolfButton';

export interface CustomModalProps {
  title?: string;
  customCancelText?: string;
  customConfirmText?: string;
  customConfirmColor?: 'primary' | 'secondary' | 'error' | 'success';
  isOpen: boolean;
  hideClose?: boolean;
  showButtons?: boolean;
  showConfirmButton?: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  children: React.ReactNode;
  maxWidth?: '400px' | '600px' | '800px' | '1024px' | '1200px';
  maxHeight?: string;
}

const CustomModal: React.FC<CustomModalProps> = ({
  title,
  customCancelText,
  customConfirmText,
  customConfirmColor,
  isOpen,
  hideClose,
  showButtons,
  showConfirmButton = true,
  onClose,
  onConfirm,
  children,
  maxWidth = '1200px',
  maxHeight,
}) => {
  const localeCommon = useTranslation('common');

  return (
    <Modal open={isOpen} onClose={onClose} closeAfterTransition className="overflow-scroll">
      <Fade in={isOpen}>
        <Box
          className="relative w-3/4 bg-white mx-auto p-2 my-4 top-32 min-h-64 overflow-scroll"
          sx={{ maxHeight: maxHeight || 'calc(100vh - 256px)', maxWidth: maxWidth }}>
          {title ? (
            <Box className="flex justify-between items-center p-4">
              <Typography variant="h3semibold" className="text-center">
                {title}
              </Typography>
              <IconButton onClick={onClose} color="primary">
                <CloseOutlined />
              </IconButton>
            </Box>
          ) : (
            <Box className="absolute top-2 right-2">
              {!hideClose && (
                <IconButton onClick={onClose} color="primary">
                  <CloseOutlined />
                </IconButton>
              )}
            </Box>
          )}
          <Box className="px-4 min-h-32">{children}</Box>
          <Box>
            {showButtons && (
              <Box className="px-4">
                <Divider />
                <Box className="flex justify-between my-2">
                  <WolfButton onClick={onClose} variant="outlined" color="secondary">
                    {customCancelText || localeCommon['cancel']}
                  </WolfButton>
                  {showConfirmButton && (
                    <WolfButton
                      onClick={onConfirm}
                      variant="outlined"
                      color={customConfirmColor || 'primary'}
                      autoFocus>
                      {customConfirmText || localeCommon['confirm']}
                    </WolfButton>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default CustomModal;
