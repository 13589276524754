import { ProcessTriggerRelation, ReactFlowEdge, ReactFlowNode } from '@models/process-map.model';
import { Process } from '@models/process.model';
import { MarkerType } from 'reactflow';
import { TRIGGER_HORIZONTAL_OFFSET, TRIGGER_VERTICAL_SPACING } from './processMapConstants';

export function drawTriggers(
  triggers: ProcessTriggerRelation[] | undefined,
  identifier: string,
  existingNodes: ReactFlowNode[],
  allProcesses: Process[],
) {
  const nodes: ReactFlowNode[] = [];
  const edges: ReactFlowEdge[] = [];

  // Check if there are no triggers for the first process
  const firstProcessNode = existingNodes.find((node) => node.isRoot);

  const firstProcessTriggers = triggers?.find((t) => t.origin.toString() === firstProcessNode?.originalId);

  if (firstProcessNode && !firstProcessTriggers?.triggers?.length) {
    // Add warning node for no triggers
    const warningNode: ReactFlowNode = {
      id: `${identifier}-no-triggers-warning`,
      position: {
        x: -TRIGGER_HORIZONTAL_OFFSET,
        y: firstProcessNode.position.y + 16,
      },
      data: {
        label: 'No trigger defined',
        preLabel: 'Warning',
        color: '#FFA500',
        triggerType: 'warning',
        parentNode: `container-${identifier}`,
        originalY: firstProcessNode.position.y + 16,
      },
      parentNode: `container-${identifier}`,
      type: 'TriggerNode',
    };
    nodes.push(warningNode);
  }

  if (!triggers || triggers.length === 0) {
    return {
      nodes,
      edges,
    };
  }

  triggers.forEach((processTrigger, idx) => {
    const sourceProcessId = `${identifier}-${processTrigger.origin}`;

    // Find the origin node to get its Y position
    const originNode = existingNodes.find((node) => node.id === sourceProcessId);
    if (!originNode) return;

    processTrigger.triggers.forEach((trigger, index) => {
      const triggerId = `${identifier}-trigger-${processTrigger.origin}-${index}`;
      const isFirstProcess = processTrigger.origin.toString() === firstProcessNode?.originalId;
      const isWarningTrigger = trigger.type === 'warning';
      const isProcessTrigger = trigger.type === 'process';

      // Determine position and connection points
      let position = {
        x: originNode.position.x,
        y: originNode.position.y - 100,
      };

      let sourceHandle = 'bottom';
      let targetHandle = 'top';

      // If it's the first process in category, place triggers on the left
      if (isFirstProcess) {
        position = {
          x: -TRIGGER_HORIZONTAL_OFFSET,
          y: originNode.position.y + index * TRIGGER_VERTICAL_SPACING,
        };
        sourceHandle = 'right';
        targetHandle = 'left';
      }

      // Find process details if it's a process trigger
      let processDetails = '';
      let auxProcess: Process | undefined;
      if (isProcessTrigger && trigger.processId) {
        auxProcess = allProcesses.find((p) => p.processId === trigger.processId);
        if (auxProcess) {
          processDetails = `${auxProcess.service?.area?.name} / ${auxProcess.service?.name}`;
        }
      }

      // Create trigger node using calculated position
      const triggerNode: ReactFlowNode = {
        id: triggerId,
        position: position,
        data: {
          label: isWarningTrigger ? 'No trigger defined' : isProcessTrigger ? auxProcess?.name || '' : trigger.name,
          preLabel: isWarningTrigger ? 'Warning' : isProcessTrigger ? processDetails : trigger.type,
          color: isWarningTrigger ? '#FFA500' : isProcessTrigger ? auxProcess?.service?.color : '#FFD700',
          triggerType: isWarningTrigger ? 'warning' : trigger.type,
          parentNode: `container-${identifier}`,
          isExternal: isProcessTrigger,
          isTrigger: true,
          backgroundColor: isProcessTrigger ? auxProcess?.service?.color : undefined,
          categoryId: auxProcess && auxProcess?.service?.serviceId + '---' + auxProcess?.category,
          originalY: position.y,
        },
        parentNode: `container-${identifier}`,
        type: 'TriggerNode',
      };

      // Create edge from trigger to process
      const triggerEdge: ReactFlowEdge = {
        id: `${identifier}-trigger-edge-${processTrigger.origin}-${index}-${idx}`,
        source: triggerId,
        target: sourceProcessId,
        sourceHandle: sourceHandle,
        targetHandle: targetHandle,
        type: 'smoothstep',
        markerEnd: {
          type: MarkerType.Arrow,
          width: 30,
          height: 30,
        },
      };

      nodes.push(triggerNode);
      edges.push(triggerEdge);
    });
  });

  return {
    nodes,
    edges,
  };
}
