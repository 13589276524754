import { Process } from '@models/process.model';
import { drawRelated } from './drawRelated';
import { drawTriggers } from './drawTriggers';
import { generateMainProcesses } from './generateMainProcessesV2';

export default function drawElementsV2(
  drawnCategory: Process[],
  startingVerticalOffset: number,
  identifier: string,
  allProcesses: Process[],
) {
  // We take all the processes and draw them, as they are related to one another with edges and isolated at the end
  const {
    nodes: auxNodes,
    edges: auxEdges,
    related,
    triggers,
  } = generateMainProcesses(drawnCategory, identifier, startingVerticalOffset);

  // TODO: We add triggers with their respective lines

  const { nodes: auxNodesTriggers, edges: auxEdgesTriggers } = drawTriggers(
    triggers,
    identifier,
    auxNodes,
    allProcesses,
  );

  // We add requests (links to processes outside the flow) with their respective lines

  const { nodes: auxNodesRelated, edges: auxEdgesRelated } = drawRelated(related, identifier, allProcesses, auxNodes);

  return {
    nodes: [...auxNodes, ...auxNodesTriggers, ...auxNodesRelated],
    edges: [...auxEdges, ...auxEdgesTriggers, ...auxEdgesRelated],
  };
}
