import { STEP_FIELDS } from '@graphql/step.queries';
import { gql } from 'graphql-request';
import { POLICY_FIELDS } from './policy.queries';

// TODO: Move this to a common file
export const PROCESS_FIELDS = gql`
  fragment ProcessFields on ProcessDto {
    processId
    category
    name
    description
    goal
    order
    location
    policies {
      policyId
      name
    }
    triggers {
      triggerId
      name
      triggerType
      triggerData
    }
    steps {
      stepId
      name
      order
      notes
      stepType
      stepData
    }
  }
`;

export const SERVICE_FIELDS = gql`
  fragment ServiceFields on ServiceDto {
    serviceId
    name
    description
    color
    order
    calendar
    areaId
    area {
      areaId
      name
      description
      color
      order
    }
  }
`;

export const getProcesses = gql`
  query getProcesses {
    getProcesses {
      ...ProcessFields
    }
  }

  ${PROCESS_FIELDS}
`;

export const getProcessesNameById = gql`
  query getProcessesNameById($id: String!) {
    getProcessesNameById(id: $id) {
      name
      processId
    }
  }
`;

export const getProcessesWithAreaService = gql`
  query getProcessesWithAreaService {
    getProcessesWithAreaService {
      ...ProcessFields
      service {
        ...ServiceFields
      }
    }
  }

  ${PROCESS_FIELDS}
  ${SERVICE_FIELDS}
`;

export const getProcessById = gql`
  query getProcessById($id: String!) {
    getProcessById(id: $id) {
      ...ProcessFields
      service {
        ...ServiceFields
      }
    }
  }

  ${PROCESS_FIELDS}
  ${SERVICE_FIELDS}
`;

export const getProcessWithTriggeredBy = gql`
  query getProcessWithTriggeredBy($id: String!) {
    getProcessWithTriggeredBy(id: $id) {
      ...ProcessFields
      generatedFromName
      generatedFrom
      triggeredBy {
        ...StepFields
        process {
          ...ProcessFields
        }
      }
      policies {
        ...PolicyFields
      }
      service {
        ...ServiceFields
      }
    }
  }
  ${PROCESS_FIELDS}
  ${STEP_FIELDS}
  ${POLICY_FIELDS}
  ${SERVICE_FIELDS}
`;

export const getProcessesWithTriggeredBy = gql`
  query getProcessesWithTriggeredBy($ids: [String!]) {
    getProcessesWithTriggeredBy(ids: $ids) {
      ...ProcessFields
      generatedFromName
      generatedFrom
      triggeredBy {
        ...StepFields
        process {
          ...ProcessFields
        }
      }
      policies {
        ...PolicyFields
      }
      service {
        ...ServiceFields
      }
    }
  }
  ${PROCESS_FIELDS}
  ${STEP_FIELDS}
  ${POLICY_FIELDS}
  ${SERVICE_FIELDS}
`;

export const getProcessesByServiceId = gql`
  query getProcessesByServiceId($id: String!) {
    getProcessesByServiceId(id: $id) {
      ...ProcessFields
      service {
        ...ServiceFields
      }
    }
  }

  ${PROCESS_FIELDS}
  ${SERVICE_FIELDS}
`;

export const createProcess = gql`
  mutation createProcess($process: ProcessCreateInput!) {
    createProcess(process: $process) {
      ...ProcessFields
    }
  }
  ${PROCESS_FIELDS}
`;

export const updateProcess = gql`
  mutation updateProcess($process: ProcessUpdateInput!) {
    updateProcess(process: $process) {
      ...ProcessFields
    }
  }
  ${PROCESS_FIELDS}
`;

export const linkProcessPolicy = gql`
  mutation linkProcessPolicy($processId: String!, $policyId: [String!]!) {
    linkProcessPolicy(processId: $processId, policyId: $policyId) {
      ...ProcessFields
    }
  }
  ${PROCESS_FIELDS}
`;

export const reorderProcesses = gql`
  mutation reorderProcesses($processes: ProcessReorderInput!) {
    reorderProcesses(processes: $processes) {
      error
      messages
    }
  }
`;

export const deleteProcess = gql`
  mutation deleteProcess($id: String!) {
    deleteProcess(id: $id) {
      affected
      messages
    }
  }
`;

const ProcessesGraphQL = {
  queries: {
    getProcesses,
    getProcessesNameById,
    getProcessesWithAreaService,
    getProcessById,
    getProcessWithTriggeredBy,
    getProcessesWithTriggeredBy,
    getProcessesByServiceId,
  },
  mutations: {
    createProcess,
    updateProcess,
    linkProcessPolicy,
    reorderProcesses,
    deleteProcess,
  },
};

export default ProcessesGraphQL;
