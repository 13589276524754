import { TrashIcon20 } from '@components/ui/WolfIcons';
import { Box, Card, CardContent, IconButton, Typography } from '@mui/material';
import React from 'react';

interface ItemCardProps {
  title: string;
  onView: () => void;
  onDelete: () => void;
}

const DrawnCategoryItem: React.FC<ItemCardProps> = ({ title, onView, onDelete }) => {
  return (
    <Card className="relative flex items-center shadow-md border border-gray-200 rounded-lg group">
      <CardContent className="flex items-center justify-between h-10 !px-2 !py-4 w-full">
        <Typography className="!text-sm" component={'span'}>
          {title.length > 35 ? title.slice(0, 35) + '...' : title}
        </Typography>
        <Box className="flex items-center space-x-1 opacity-10 group-hover:opacity-100 transition-opacity">
          {/* TODO: Add view button */}
          {/* <IconButton onClick={onView} className="w-6 h-6">
            <VisibilityOutlined className="!text-base" />
          </IconButton> */}
          <IconButton onClick={onDelete} className="w-6 h-6">
            <TrashIcon20 className="!text-base" />
          </IconButton>
        </Box>
      </CardContent>
    </Card>
  );
};

export default DrawnCategoryItem;
