import ProcessMapLegendModal from '@components/modals/ProcessMapLegendModal';
import WolfChip from '@components/ui/WolfChip';
import { AreaIcon24, ServicesIcon24 } from '@components/ui/WolfIcons';
import WolfSearchBorderless from '@components/ui/WolfSearchBorderless';
import { graphQlClient } from '@config/graphqlClient';
import AreasGraphQL from '@graphql/area.queries';
import { useTranslation } from '@hooks/useTranslation';
import { getAreasNameWServProcRequest } from '@models/area.model';
import { Service } from '@models/service.model';
import { Add, ArrowDropDown, CloseOutlined, GroupWorkOutlined, HelpOutline, PolicyOutlined } from '@mui/icons-material';
import {
  Box,
  Collapse,
  Divider,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import { isColorDark } from '@utils/isColorDark';
import { ChangeEvent, useEffect, useRef, useState } from 'react';

interface WolfSearchModalProps {
  toggleFilter: () => void;
  clickResult: (result: any) => void;
}

enum ResultType {
  AREA = 'Area',
  SERVICE = 'Service',
  CATEGORY = 'Category',
}

const WolfSearchModal: React.FC<WolfSearchModalProps> = ({ toggleFilter, clickResult }) => {
  const localeMenu = useTranslation('menu');
  const localeCommon = useTranslation('common');
  const theme = useTheme();
  const modalRef = useRef<HTMLDivElement>(null);

  const chipCategories: { text: string; value: ResultType | 'All' }[] = [
    { text: localeCommon['all'], value: 'All' },
    { text: localeMenu['areas'], value: ResultType.AREA },
    { text: localeMenu['services'], value: ResultType.SERVICE },
    { text: localeMenu['categories'], value: ResultType.CATEGORY },
  ];

  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<{ text: string; value: ResultType | 'All' }>({
    text: localeCommon['all'],
    value: 'All',
  });
  const [searchArray, setSearchArray] = useState<any[]>([]);
  const [allResults, setAllResults] = useState<any[]>([]);
  const [resultsByCategory, setResultsByCategory] = useState<any[]>([]);
  const [dataTime, setDataTime] = useState<number>(0);
  const [resultsMessage, setResultsMessage] = useState(localeMenu['noResults']);
  const [isLegendOpen, setIsLegendOpen] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        setAllResults([]);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalRef]);

  const handleFocus = () => {
    if (isDataStale()) {
      getData();
    }
  };

  const REFRESH_PERIOD = 5 * 60 * 1000; // 5 minutes

  const isDataStale = () => {
    const currentTime = new Date().getTime();
    return currentTime - dataTime > REFRESH_PERIOD;
  };

  const getData = async () => {
    try {
      const data: getAreasNameWServProcRequest = await graphQlClient.request(
        AreasGraphQL.queries.getAreasNameWServProc,
      );
      setDataTime(new Date().getTime());
      setSearchArray(data.getAreas.sort((a: any, b: any) => (a.order > b.order ? 1 : -1)));
    } catch (e: any) {}
  };

  const highlightSearchTerm = (text: string) => {
    const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));
    return (
      <span>
        {parts.map((part, index) =>
          part.toLowerCase() === searchTerm.toLowerCase() ? (
            <span key={index} style={{ backgroundColor: theme.palette.utilityGreen.main, fontWeight: 'bold' }}>
              {part}
            </span>
          ) : (
            part
          ),
        )}
      </span>
    );
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term.length === 0) {
      setAllResults([]);
      return;
    } else {
      setOpen(true);
    }

    if (term.length < 3) {
      setResultsMessage(localeMenu['searchResultsMinLength']);
      setAllResults([]);
      return;
    }
    setResultsMessage(localeMenu['noResults']);
    const contextAreaId = new URLSearchParams(window.location.search).get('areaId');
    const contextServiceId = new URLSearchParams(window.location.search).get('serviceId');
    const results: {
      name: string;
      type: ResultType;
      id: string;
      score: number;
      color: string;
      category: string;
      serviceId?: string;
    }[] = [];
    const seenCategories = new Set<string>(); // Track seen categories

    searchArray.forEach((area) => {
      if (area?.name?.toLowerCase().includes(term.toLowerCase())) {
        results.push({
          name: area.name || '',
          type: ResultType.AREA,
          id: area.areaId || '',
          score: 1,
          color: area.color || '',
          category: ResultType.AREA,
        });
      }
      area?.services?.forEach((service: Partial<Service>) => {
        let serviceScore = 3;
        if (service.areaId === contextAreaId) {
          serviceScore += 10;
        }
        if (service?.name?.toLowerCase().includes(term.toLowerCase())) {
          results.push({
            name: service.name || '',
            type: ResultType.SERVICE,
            id: service.serviceId || '',
            score: serviceScore,
            color: service.color || '',
            category: ResultType.SERVICE,
          });
        }
        service?.processes?.forEach((process) => {
          if (
            process.category &&
            process.category.toLowerCase().includes(term.toLowerCase()) &&
            !seenCategories.has(process.category.toLowerCase())
          ) {
            let processScore = 5;
            if (process.serviceId === contextServiceId || service?.areaId === contextAreaId) {
              processScore += 20;
            }
            seenCategories.add(process.category.toLowerCase()); // Mark this category as seen
            results.push({
              name: process.category,
              type: ResultType.CATEGORY,
              id: process.processId,
              score: processScore,
              color: service.color || '',
              category: ResultType.CATEGORY,
              serviceId: service.serviceId,
            });
          }
        });
      });
    });
    setAllResults(results);
  };

  const allSearchResults = (selectedCategory.value === 'All' ? allResults : resultsByCategory)
    .sort((a, b) => a.category.localeCompare(b.category)) // Sort by category
    .sort((a, b) => (a.score > b.score ? -1 : 1)) // Sort by score
    .reduce((acc, element, index, array) => {
      // Check if this is the first element of a new category and count items
      if (index === 0 || array[index - 1].category !== element.category) {
        const count = array.filter((item) => item.category === element.category).length;
        acc.push(
          <div key={`divider-${element.category}`}>
            <Typography variant="body12semibold" color="textSecondary">
              {element.category} ({count})
            </Typography>
            <Divider />
          </div>,
        );
      }
      acc.push(
        <ListItem button key={index} onClick={() => handleListItemClick(element)}>
          {element.type && (
            <Box
              className="flex items-center justify-between mr-2 p-0.5 rounded-full"
              sx={{
                backgroundColor: element.color,
                color: isColorDark(element.color) ? theme.palette.common.white : theme.palette.primary.main,
              }}>
              <Icon className="!flex">
                {element.type === 'Area' ? (
                  <AreaIcon24 />
                ) : element.type === 'Policy' ? (
                  <PolicyOutlined />
                ) : element.type === 'Service' ? (
                  <ServicesIcon24 />
                ) : element.type === 'Category' ? (
                  <GroupWorkOutlined />
                ) : null}
              </Icon>
            </Box>
          )}
          <ListItemText className="text-body14semibold font-semibold" primary={highlightSearchTerm(element.name)} />
        </ListItem>,
      );
      return acc;
    }, [] as React.ReactNode[]);

  const handleListItemClick = (result: any) => {
    clickResult(result);
    setSearchTerm('');
    setAllResults([]);
    setOpen(false);
  };

  const handleCategoryChange = (category: { text: string; value: ResultType | 'All' }) => {
    setSelectedCategory(category);
    setResultsByCategory(allResults.filter((result) => result.category === category.value));
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    setAllResults([]);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const handleLegendToggle = () => {
    setIsLegendOpen(!isLegendOpen);
  };

  return (
    <>
      <Box className="flex justify-between h-12">
        <Box className="flex w-full items-center justify-between">
          <WolfSearchBorderless
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearch}
            onClear={handleClearSearch}
            onFocus={handleFocus}
          />
        </Box>
        <Box
          className="flex items-center justify-center p-4 border-solid border-0 border-l border-gray-200"
          onClick={toggleFilter}>
          <Icon color="primary">
            <Add />
          </Icon>
          <Icon color="primary">
            <ArrowDropDown />
          </Icon>
        </Box>
        <Box
          className="flex items-center justify-center p-4 border-solid border-0 border-l border-gray-200 cursor-pointer"
          onClick={handleLegendToggle}>
          <Icon color="primary">
            <HelpOutline />
          </Icon>
        </Box>
      </Box>

      <ProcessMapLegendModal isOpen={isLegendOpen} onClose={handleLegendToggle} />

      <Collapse in={open}>
        <Box className="flex flex-wrap justify-between bg-primaryFrozen border border-solid border-primaryIcy p-2">
          {chipCategories.map((category) => (
            <WolfChip
              key={category.value}
              label={category.text}
              selected={selectedCategory.value === category.value}
              onClick={() => handleCategoryChange(category)}
              customColor={
                selectedCategory.value === category.value ? theme.palette.common.white : theme.palette.primaryDark.main
              }
              customBgColor={
                selectedCategory.value === category.value ? theme.palette.primaryDark.main : theme.palette.common.white
              }
              customPadding={theme.spacing(2)}
            />
          ))}
          <IconButton onClick={closeDialog} color="primary">
            <CloseOutlined />
          </IconButton>
        </Box>

        <Box className="!p-0">
          {allResults.length === 0 ? (
            <Box className="flex justify-center items-center h-48">
              <Typography>{resultsMessage}</Typography>
            </Box>
          ) : (
            <Paper className="px-2">
              <List>{allSearchResults}</List>
            </Paper>
          )}
        </Box>
      </Collapse>
    </>
  );
};

export default WolfSearchModal;
