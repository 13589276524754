import frameworkData from '../assets/framework.json';

interface FrameworkData {
  [key: string]: any;
}

const StaticFrameworkComponent: React.FC = () => {
  const renderSection = (title: string, content: any) => (
    <div className="mb-4">
      <h2 className="text-2xl font-bold mb-2">{title}</h2>
      {typeof content === 'string' ? (
        <p className="text-gray-700">{content}</p>
      ) : (
        <div className="ml-4">
          {Object.entries(content).map(([key, value]) => (
            <div key={key} className="mb-2">
              <h3 className="text-lg font-semibold">{key}</h3>
              {typeof value === 'string' ? (
                <p className="text-gray-600">{value + ':b'}</p>
              ) : key === 'Description' ? (
                <p className="text-gray-600">{key + ':a' + value}</p>
              ) : key === 'Examples' ? (
                <div className="ml-4">{renderExamples(key, value)}</div>
              ) : key === 'Attributes' ? (
                <div className="ml-4">{renderAttributes(key, value)}</div>
              ) : null}
            </div>
          ))}
        </div>
      )}
    </div>
  );

  const renderExamples = (title: string, content: any) => (
    <div className="mb-4">
      <h2 className="text-2xl font-bold mb-2">{title}</h2>
      {typeof content === 'string' ? (
        <p className="text-gray-700">{content}</p>
      ) : (
        <div className="ml-4">
          {Object.entries(content).map(([key, value]) => (
            <div key={key} className="mb-2">
              <span className="text-base font-semibold">{key + ': '}</span>
              <span className="text-gray-600">{value as string}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );

  const renderAttributes = (title: string, content: any) => (
    <div className="mb-4">
      <h2 className="text-2xl font-bold mb-2">{title}</h2>
      {typeof content === 'string' ? (
        <p className="text-gray-700">{content}</p>
      ) : (
        <div className="ml-4">
          {Object.entries(content).map(([key, value]) => (
            <div key={key} className="mb-2">
              <span className="text-base font-semibold">{key + ': '}</span>
              <span className="text-gray-600">{value as string}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );

  return (
    <div className="p-6 max-w-4xl mx-auto">
      {Object.entries(frameworkData).map(([sectionTitle, sectionContent]) => (
        <div key={sectionTitle} className="mb-8">
          {renderSection(sectionTitle, sectionContent)}
        </div>
      ))}
    </div>
  );
};

export default StaticFrameworkComponent;
