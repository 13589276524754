import DrawnCategoryItem from '@components/processMapAuxV2/DrawnCategoryItem';
import CategoryContainer from '@components/processMapAuxV2/NodeCategoryContainer';
import { ProcessNode } from '@components/processMapAuxV2/NodeProcess';
import TimingNode from '@components/processMapAuxV2/NodeTiming';
import { TriggerNode } from '@components/processMapAuxV2/NodeTrigger';
import SearchFilterProcessMap from '@components/processMapAuxV2/SearchFilterProcessMap';
import WolfChip from '@components/ui/WolfChip';
import WolfDropdown from '@components/ui/WolfDropdown';
import { graphQlClient } from '@config/graphqlClient';
import AreasGraphQL from '@graphql/area.queries';
import ProcessesGraphQL from '@graphql/process.queries';
import ServicesGraphQL from '@graphql/service.queries';
import { useLocationQuery } from '@hooks/useLocationQuery';
import { useTranslation } from '@hooks/useTranslation';
import { Area, getAreasRequest } from '@models/area.model';
import { StoredProcessMapState } from '@models/process-map.model';
import { getProcessesWithTriggeredByRequest, Process } from '@models/process.model';
import { getServicesRequest } from '@models/service.model';
import { Box, Collapse, Typography, useTheme } from '@mui/material';
import { useAppSelector } from '@redux/hooks';
import { RootState } from '@redux/store';
import { calculateNextYPosition } from '@utils/processMapv2/calculateNextYPosition';
import drawElementsV2 from '@utils/processMapv2/drawElementsV2';
import { EXPANDED_CONTAINER_BUFFER, EXPANDED_NODE_BUFFER } from '@utils/processMapv2/processMapConstants';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactFlow, { Controls, MarkerType, MiniMap } from 'reactflow';
import 'reactflow/dist/style.css';

const PROCESS_MAP_STORAGE_KEY = 'processMapState';

const ProcessMap: React.FC = () => {
  const { isImpersonating } = useAppSelector((state: RootState) => state.impersonate);

  const [areas, setAreas] = useState<any[]>([]);
  const [selectedArea, setSelectedArea] = useState<Area | null>();
  const [services, setServices] = useState<any[]>([]);
  const [selectedAreaId, setSelectedAreaId] = useState<string>('');
  const [selectedServiceId, setSelectedServiceId] = useState<string>('');
  const [selectedCategory, setSelectedCategory] = useState<string[]>([]);
  const [flowKey, setFlowKey] = useState<number>(0);
  const [drawnCategories, setDrawnCategories] = useState<
    { id: string; originalId: string; height: number; service: string; area: string; label: string }[]
  >([]);
  const [categoriesToDraw, setCategoriesToDraw] = useState<string[]>([]);

  const [elements, setElements] = useState<any[]>([]);
  const [edges, setEdges] = useState<any[]>([]);
  const [processes, setProcesses] = useState<any[]>([]);
  const localeProcMap = useTranslation('processMap');
  const localeCommon = useTranslation('common');

  const query = useLocationQuery();
  const history = useHistory();
  const theme = useTheme();
  const [showMenu, setShowMenu] = useState(false);

  // Add a new state to track when initial data is loaded
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [savedStateLoaded, setSavedStateLoaded] = useState(false);

  useEffect(() => {
    const areaId = query.get('areaId');
    const serviceId = query.get('serviceId');
    if (areaId) {
      setSelectedAreaId(areaId);
    }
    if (serviceId) {
      setSelectedServiceId(serviceId);
    }
  }, [query]);

  useEffect(() => {
    if (!isDataLoaded || !savedStateLoaded) return;

    const state: StoredProcessMapState = {
      selectedAreaId,
      selectedServiceId,
      categoriesToDraw,
      selectedCategory,
    };
    console.log('saving state', state);
    localStorage.setItem(PROCESS_MAP_STORAGE_KEY, JSON.stringify(state));
  }, [isDataLoaded, selectedAreaId, selectedServiceId, categoriesToDraw, selectedCategory]);

  useEffect(() => {
    console.log('categoriesToDraw', categoriesToDraw);

    if (!savedStateLoaded) return;

    console.log('drawing elements');

    for (const category of categoriesToDraw) {
      const categoryName = category.split('---')[1];
      const serviceId = category.split('---')[0];
      drawElementsGlobal(
        processes,
        services.filter((service) => service.serviceId === serviceId)[0]?.name,
        serviceId,
        categoryName,
        selectedArea?.name,
      );
    }

    if (categoriesToDraw.length === 0) {
      setDrawnCategories([]);
      setElements((prev) => []);
      setEdges((prev) => []);
    }
  }, [categoriesToDraw, savedStateLoaded]);

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        await Promise.all([getAreas(), getServices(), getAllProcesses()]);
        setIsDataLoaded(true);
      } catch (error) {
        console.error('Error loading initial data:', error);
      }
    };

    loadInitialData();
  }, []);

  useEffect(() => {
    const loadSavedState = async () => {
      if (!isDataLoaded) return;

      try {
        const savedState = localStorage.getItem(PROCESS_MAP_STORAGE_KEY);
        if (savedState) {
          const parsedState: StoredProcessMapState = JSON.parse(savedState);

          setSelectedAreaId(parsedState.selectedAreaId);
          setSelectedArea(areas.find((area) => area.areaId === parsedState.selectedAreaId) || null);
          setSelectedServiceId(parsedState.selectedServiceId);
          setSelectedCategory(parsedState.selectedCategory);
          addProcessCategories(categoriesToDraw, parsedState);
        }
        setSavedStateLoaded(true);
      } catch (error) {
        console.error('Error loading saved process map state:', error);
        localStorage.removeItem(PROCESS_MAP_STORAGE_KEY);
        setSavedStateLoaded(true);
      }
    };

    loadSavedState();
  }, [isDataLoaded, areas, services, processes]);

  async function addProcessCategories(categoriesToDraw: any[], parsedState: any) {
    for (const category of parsedState.categoriesToDraw) {
      await new Promise((resolve) => {
        setTimeout(() => {
          if (!categoriesToDraw.includes(category)) {
            setCategoriesToDraw((prev) => [...prev, category]);
          }
          resolve(void 0);
        }, 100);
      });
    }
  }

  const getAreas = async () => {
    try {
      const data: getAreasRequest = await graphQlClient.request(AreasGraphQL.queries.getAreas);
      const sortedAreas = data.getAreas.sort((a, b) => (a.order > b.order ? 1 : -1));
      setAreas(sortedAreas);

      // Only set default area if no saved state
      if (!localStorage.getItem(PROCESS_MAP_STORAGE_KEY)) {
        setSelectedAreaId('1e7e511f-9118-4a80-ae18-c9f3cb940ff3');
        setSelectedArea(sortedAreas.find((area) => area.areaId === '1e7e511f-9118-4a80-ae18-c9f3cb940ff3') || null);
      }
    } catch (e: any) {
      console.error('Error loading areas:', e);
    }
  };

  const getServices = async () => {
    try {
      const data: getServicesRequest = await graphQlClient.request(ServicesGraphQL.queries.getServices);
      setServices(data.getServices);

      // Only set default service if no saved state
      if (!localStorage.getItem(PROCESS_MAP_STORAGE_KEY)) {
        setSelectedServiceId('7c5a740d-cb24-4bc4-98a1-5b89d66a0545');
      }
    } catch (e: any) {
      console.error('Error loading services:', e);
    }
  };

  const getAllProcesses = async () => {
    try {
      const data: getProcessesWithTriggeredByRequest = await graphQlClient.request(
        ProcessesGraphQL.queries.getProcessesWithTriggeredBy,
      );
      setProcesses(data?.getProcessesWithTriggeredBy);

      // Only set default category if no saved state
      if (!localStorage.getItem(PROCESS_MAP_STORAGE_KEY)) {
        // handleCategoryChange([{ text: 'Offers', value: '7c5a740d-cb24-4bc4-98a1-5b89d66a0545---Offers' }]);
      }
    } catch (e: any) {
      console.error('Error loading processes:', e);
    }
  };

  const resetFlow = () => {
    setFlowKey((prevKey) => prevKey + 1);
  };

  const nodeTypes = useMemo(
    () => ({
      ProcessV2: ProcessNode,
      CategoryContainer: CategoryContainer,
      TriggerNode: TriggerNode,
      TimingNode: TimingNode,
    }),
    [],
  );

  const handleAreaChange = (updatedAreas: { text: string; value: string }[]) => {
    if (updatedAreas.length === 0) {
      setSelectedAreaId('');
    } else {
      setSelectedAreaId(updatedAreas[0].value);
      setSelectedArea(areas.filter((area) => area.areaId === updatedAreas[0].value)[0]);
    }
    setSelectedServiceId('');
  };

  const handleServiceChange = (updatedServices: { text: string; value: string }[]) => {
    console.log('services');
    if (updatedServices.length === 0) {
      setSelectedServiceId('');
    } else {
      setSelectedServiceId(updatedServices[0].value);
    }
  };

  const handleCategoryChange = (updatedCategories: { text: string; value: string }[]) => {
    const newValue = updatedCategories.map((item) => item.value);

    // Format each category with serviceId prefix
    const formattedCategories = newValue.map((category: string) =>
      category.includes('---') ? category : `${selectedServiceId}---${category}`,
    );

    setSelectedCategory(formattedCategories);
    setCategoriesToDraw(updatedCategories.map((item) => item.value));
    return;

    // Trigger drawing of new categories
    const drawnCategoryLabels = drawnCategories.map((cat) => cat.label);
    const uniqueNewCategories = formattedCategories.filter(
      (category: string) => !drawnCategoryLabels.includes(category.split('---')[1]),
    );

    console.log('uniqueNewCategories', uniqueNewCategories);
    if (uniqueNewCategories.length > 0) {
      setCategoriesToDraw((prev) => [...prev, ...uniqueNewCategories]);
    }
  };

  const handleToggleViewCategory = (categoryId: string) => {
    console.log('categoryId', categoryId);
  };

  const handleDeleteCategory = async (categoryId: string) => {
    console.log('Deleting category:', categoryId);

    // Find the full category info from drawnCategories
    const categoryToRemove = drawnCategories.find((cat) => cat.id === categoryId);
    if (!categoryToRemove) return;

    // Remove from tracking states first
    setSelectedCategory((prev) => prev.filter((category) => category !== categoryToRemove.originalId));

    setCategoriesToDraw((prev) => prev.filter((category) => category !== categoryToRemove.originalId));

    // Remove from drawn categories
    setDrawnCategories((prev) => prev.filter((cat) => cat.id !== categoryId));

    // Clean up elements and edges
    const categoryPrefix = categoryId; // The ID is already in the correct format
    setElements((prev) =>
      prev.filter((el) => {
        // Keep elements that don't start with this category's ID and aren't its container
        return !el.id.startsWith(categoryPrefix) && el.id !== `container-${categoryPrefix}`;
      }),
    );

    setEdges((prev) =>
      prev.filter((edge) => {
        // Keep edges that don't connect to this category's nodes
        return !edge.source.startsWith(categoryPrefix) && !edge.target.startsWith(categoryPrefix);
      }),
    );
  };

  const drawElementsGlobal = async (
    allData: Process[],
    serviceName: string,
    selectedServiceId: string,
    selectedCategory: string,
    areaName?: string,
  ) => {
    // Validate that the category still exists
    const categoryExists = allData.some(
      (process) => process?.service?.serviceId === selectedServiceId && process.category === selectedCategory,
    );

    if (!categoryExists) {
      console.warn(`Category ${selectedCategory} no longer exists for service ${selectedServiceId}`);
      // Remove the invalid category from tracking
      setCategoriesToDraw((prev) => prev.filter((cat) => cat !== `${selectedServiceId}---${selectedCategory}`));
      setSelectedCategory((prev) => prev.filter((cat) => cat !== `${selectedServiceId}---${selectedCategory}`));
      return;
    }

    // This is needed to cleary the drawing
    resetFlow();

    const data = allData
      .filter((item) => item?.service?.serviceId === selectedServiceId)
      .filter((item) => item?.category === selectedCategory);

    const identifier = selectedServiceId.slice(0, 8) + '---' + selectedCategory;

    // If we go this route to accumulate categories, we need to check if we are already drawing that category
    if (drawnCategories.some((item) => item.id === identifier)) {
      return;
    }

    const startingVerticalOffset = drawnCategories?.reduce((acc, curr) => acc + curr.height, 0);

    console.log('startingVerticalOffset', startingVerticalOffset);

    const drawnCategory = data;
    if (!drawnCategory || drawnCategory.length === 0) {
      return;
    }

    const { nodes, edges: newDrawnEdges } = drawElementsV2(
      drawnCategory,
      startingVerticalOffset || 0,
      identifier,
      processes,
    );

    let newNodes = [...elements, ...nodes.filter((item: any) => item !== undefined)];
    let newEdges = [...edges, ...newDrawnEdges.filter((item: any) => item !== undefined)];

    // Filter out nodes and edges for categories that should be removed
    for (const category of drawnCategories) {
      console.log('removing?');

      if (!categoriesToDraw.includes(category.originalId)) {
        console.log('removing', category.id);
        newNodes = newNodes.filter((item: any) => item.id !== 'container-' + category.id);
        newNodes = newNodes.filter((item: any) => !item.id.startsWith(category.id));
        newEdges = newEdges.filter(
          (item: any) => item.source !== 'container-' + category.id && item.target !== 'container-' + category.id,
        );
        newEdges = newEdges.filter((item: any) => !item.id.startsWith(category.id));
        setDrawnCategories((prev) => prev.filter((item) => item.id !== category.id));
      }
    }

    console.log('newNodes', newNodes);
    console.log('newEdges', newEdges);
    setElements(newNodes);
    setEdges(newEdges);

    const totalHeight = nodes.find((item: any) => item.id === 'container-' + identifier)?.data?.height || 0;

    setDrawnCategories((prev) => [
      ...prev,
      {
        id: identifier,
        originalId: selectedServiceId + '---' + selectedCategory,
        height: totalHeight + 100,
        service: serviceName,
        area: areaName || '',
        label: selectedCategory,
      },
    ]);
  };

  // **** CLICK HANDLERS ****

  const onNodeClick = (event: any) => {
    // Look through the event path to find the element with our data attributes
    const path = event.nativeEvent.composedPath();
    const expandCategory = path.find((element: any) => element.dataset && element.dataset.action === 'category-expand');

    if (expandCategory) {
      console.log('expandCategory', expandCategory.dataset);
      handleCategoryExpand(expandCategory.dataset.categoryId, true);
      return;
    }

    const collapseCategory = path.find(
      (element: any) => element.dataset && element.dataset.action === 'category-collapse',
    );

    if (collapseCategory) {
      console.log('collapseCategory', collapseCategory.dataset);
      handleCategoryExpand(collapseCategory.dataset.categoryId, false);
      return;
    }

    const expandProcess = path.find(
      (element: any) => element.dataset && element.dataset.action === 'individual-expand',
    );
    if (expandProcess) {
      handleProcessExpand(expandProcess.dataset.id, expandProcess.dataset.expanded);
      return;
    }

    const addCategory = path.find((element: any) => element.dataset && element.dataset.action === 'category-add');
    if (addCategory) {
      handleCategoryAdd(addCategory.dataset.id);
      return;
    }

    const showCategoryLink = path.find(
      (element: any) => element.dataset && element.dataset.action === 'category-show-link',
    );
    if (showCategoryLink) {
      handleCategoryLink(showCategoryLink.dataset.origin, showCategoryLink.dataset.id);
      return;
    }
  };

  const onEdgeClick = (event: any, edge: any) => {
    const connectedNodes = [edge.source, edge.target];

    if (edge.id.startsWith('temp-link')) {
      return;
    }

    const newNodes = elements.map((node) => ({
      ...node,
      style: connectedNodes.includes(node.id) ? { border: '2px solid red' } : {},
    }));

    setElements(newNodes);
  };

  const onPaneClick = () => {
    const newNodes = elements.map((node) => ({
      ...node,
      style: {},
    }));
    setElements(newNodes);
  };

  const areaList = areas.map((area) => ({
    text: area.name,
    value: area.areaId,
  }));

  const serviceList = services
    .filter((item) => item.areaId === selectedAreaId)
    .map((service) => ({
      text: service.name,
      value: service.serviceId,
    }));

  const uniqueCategories = Array.from(
    new Set(
      processes
        .filter((process) => process.service.serviceId === selectedServiceId)
        .filter((process) => process.category)
        .map((process) => process.category),
    ),
  );

  const categoryList = uniqueCategories.map((category) => ({
    text: category,
    value: `${selectedServiceId}---${category}`,
  }));

  if (isImpersonating) {
    return <Typography variant="h4">You need to stop impersonating before using the processMap</Typography>;
  }

  const handleSearchResult = (result: any) => {
    console.log('Search result:', result);

    if (result.type === 'Category') {
      // Format the category ID to match the chip format
      const categoryId = result.serviceId + '---' + result.name;

      // Add the category to draw if not already present
      setCategoriesToDraw((prev) => {
        if (prev.includes(categoryId)) return prev;
        return [...prev, categoryId];
      });

      setSelectedCategory((prev) => {
        if (prev.includes(categoryId)) return prev;
        return [...prev, categoryId];
      });
    }

    if (result.type === 'Service') {
      // First set the area
      const service = services.find((s) => s.serviceId === result.id);
      if (!service) return;

      // Set the area if it's different
      if (selectedAreaId !== service.areaId) {
        setSelectedAreaId(service.areaId);
        setSelectedArea(areas.find((area) => area.areaId === service.areaId) || null);
      }

      console.log('service', service);

      // Set the service
      setSelectedServiceId(result.id);

      // Show menu to display categories
      setShowMenu(true);
    }

    if (result.type === 'Area') {
      setSelectedAreaId(result.id);
      setSelectedArea(areas.find((area) => area.areaId === result.id) || null);
      setSelectedServiceId('');
      setShowMenu(true);
    }
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleCategoryLink = (origin: string, target: string) => {
    const tempEdgeId = `temp-link-${origin}-${target}`;

    // Check if the edge already exists
    const existingEdge = edges.find((edge) => edge.id === tempEdgeId);

    if (existingEdge) {
      // Remove the edge and clean up highlighting
      setEdges((prev) => prev.filter((edge) => edge.id !== tempEdgeId));
      setElements(elements.map((node) => ({ ...node, style: {} })));
      return;
    }

    const originNode = elements.find((node) => node.id === origin);
    const targetContainerId = 'container-' + target.slice(0, 8) + '---' + target.split('---')[1];
    const targetContainer = elements.find((node) => node.id === targetContainerId);

    // Only proceed if both nodes exist
    if (!originNode || !targetContainer) {
      console.warn('Origin or target node not found');
      return;
    }

    // Highlight the connected nodes
    const connectedNodes = [originNode.id, targetContainer.id];
    const newNodes = elements.map((node) => ({
      ...node,
      style: connectedNodes.includes(node.id) ? { border: '2px solid #2196f3' } : {},
    }));

    // Create a temporary edge between the nodes
    const newEdge = {
      id: tempEdgeId,
      source: origin,
      target: targetContainerId,
      type: 'smoothstep',
      style: { stroke: '#2196f3', strokeWidth: 2 },
      markerEnd: {
        type: MarkerType.Arrow,
        width: 30,
        height: 20,
      },
    };

    setElements(newNodes);
    setEdges((prev) => [...prev, newEdge]);
  };

  const handleCategoryExpand = (catId: string, isExpanding: boolean) => {
    const serviceId = catId.split('---')[0];
    const category = catId.split('---')[1];
    const identifier = serviceId.slice(0, 8) + '---' + category;
    const containerNode = elements.find((node: any) => node.id === `container-${identifier}`);

    if (!containerNode) return;

    // First: Get all nodes that need to be updated
    const nodesInContainer = elements.filter(
      (node: any) => node.parentNode === containerNode.id && node.id !== containerNode.id,
    );

    // Second: Calculate new positions for process nodes
    const updatedNodes = elements
      .filter((item: any) => item.parentNode === containerNode.id || item.id.startsWith(identifier))
      .map((node: any) => {
        if (node.type === 'ProcessV2' && !node.data.isExternal && node.id.startsWith(identifier)) {
          const nodeIsExpanding = !node.data.expand;
          const newY = nodeIsExpanding
            ? calculateNextYPosition(
                elements.filter((item: any) => item.parentNode === containerNode.id),
                node.position.x,
                node.position.y,
              )
            : node.data.originalY;

          return {
            ...node,
            data: {
              ...node.data,
              expand: nodeIsExpanding,
            },
            position: {
              ...node.position,
              y: newY,
            },
          };
        }
        if (
          (node.type === 'TriggerNode' || node.type === 'ProcessV2') &&
          node.id.startsWith(identifier + '-trigger') &&
          node.position.y > 100 &&
          node.position.x > 100
        ) {
          const nodeIsExpanding = !node.data.expand;
          const newY = nodeIsExpanding
            ? calculateNextYPosition(elements, node.position.x, node.position.y)
            : node.data.originalY;

          return {
            ...node,
            data: {
              ...node.data,
              expand: nodeIsExpanding,
            },
            position: {
              ...node.position,
              y: newY,
            },
          };
        }
        if (node.type === 'ProcessV2' && node.data.isExternal) {
          return {
            ...node,
            position: {
              ...node.position,
              y: isExpanding ? node.position.y + 100 : node.data.originalY,
            },
          };
        }
        return node;
      });

    // Third: Calculate new container height
    let newContainerHeight = 0;
    if (isExpanding) {
      const maxY = Math.max(
        ...nodesInContainer.map((node: any) => {
          const nodeHeight = node.data?.expand ? (node.data?.steps?.length || 0) * 50 : 0;
          return node.position.y + nodeHeight;
        }),
      );
      newContainerHeight = maxY + containerNode.data.height + EXPANDED_CONTAINER_BUFFER;
    } else {
      const minRequiredHeight = Math.max(...nodesInContainer.map((node: any) => node.position.y + 50));
      newContainerHeight = Math.max(containerNode.data.originalHeight, minRequiredHeight);
    }

    console.log('newContainerHeight', newContainerHeight);
    // Fourth: Update container with new height
    const updatedContainer = {
      ...containerNode,
      data: {
        ...containerNode.data,
        height: newContainerHeight,
      },
    };

    // Fifth: Calculate positions for containers below if needed
    const containersBelow = elements
      .filter((item: any) => item.id !== updatedContainer.id)
      .filter((item: any) => item.id.startsWith('container-'))
      .filter((item: any) => item.position.y > containerNode.position.y)
      .sort((a, b) => a.position.y - b.position.y);

    let adjustedContainers: any[] = [];
    let currentY = containerNode.position.y + newContainerHeight + EXPANDED_NODE_BUFFER;

    for (const container of containersBelow) {
      adjustedContainers.push({
        ...container,
        position: {
          ...container.position,
          y: currentY,
        },
      });
      currentY += container.data.height + EXPANDED_NODE_BUFFER;
    }

    // Finally: Combine all updates and set elements once
    const finalElements = [
      updatedContainer,
      ...adjustedContainers,
      ...elements
        .filter((item: any) => !updatedNodes.some((upd: any) => upd.id === item.id))
        .filter((item: any) => item.id !== containerNode.id)
        .filter((item: any) => !adjustedContainers.some((adj: any) => adj.id === item.id)),
      ...updatedNodes,
    ];

    setElements(finalElements);
  };

  const handleProcessExpand = (processId: string, isExpanded: string) => {
    const isCurrentlyExpanded = isExpanded === 'true';
    const targetNode = elements.find((item: any) => item.id === processId);
    const originalX = targetNode?.position.x;
    const originalY = targetNode?.position.y;
    const containerNode = elements.find((item: any) => item.id === targetNode?.parentNode);

    if (!targetNode || !containerNode) return;

    const nodesInSameColumn = elements.filter(
      (item: any) =>
        item?.parentNode === targetNode?.parentNode &&
        Math.abs(item?.position?.x - originalX) < 50 &&
        item?.id !== processId &&
        item?.position?.y > originalY,
    );

    const expandedHeight = targetNode?.data?.steps?.length * 40 + EXPANDED_NODE_BUFFER;

    // Find all related nodes that need to be moved
    const relatedNodes = elements.filter((node: any) => {
      const isRelated = edges.some(
        (edge) =>
          (edge.source === processId && edge.target === node.id) ||
          (edge.target === processId && edge.source === node.id),
      );
      return isRelated && node.type === 'ProcessV2' && node.data.isExternal;
    });

    // Update positions of nodes below the expanded one
    const newNodes = nodesInSameColumn.map((node: any) => {
      const newY = !isCurrentlyExpanded ? node.position.y + expandedHeight : node.data.originalY;
      return {
        ...node,
        position: {
          ...node.position,
          y: newY,
        },
      };
    });

    const adjustedRelatedNodes = relatedNodes.map((node: any) => {
      return {
        ...node,
        position: {
          ...node.position,
          y: !isCurrentlyExpanded ? node.position.y + expandedHeight / 2.5 : node.data.originalY,
        },
      };
    });

    // Find the lowest node position after expansion
    const lowestNodeY =
      Math.max(
        ...newNodes.map((node) => node.position.y),
        !isCurrentlyExpanded ? targetNode.position.y + expandedHeight : targetNode.position.y,
      ) + 100; // Add padding

    if (containerNode) {
      let newHeight = containerNode.data.height;

      // Calculate new height when expanding/collapsing
      if (!isCurrentlyExpanded && lowestNodeY > containerNode.data.height) {
        newHeight = lowestNodeY;
      } else if (isCurrentlyExpanded) {
        const allNodesInContainer = elements.filter(
          (node) => node.parentNode === containerNode.id && node.id !== containerNode.id,
        );
        const minRequiredHeight =
          Math.max(
            ...allNodesInContainer.map(
              (node) => node.position.y + (node.data?.expand ? (node.data?.steps?.length || 0) * 40 : 0),
            ),
          ) + 100;
        newHeight = Math.max(containerNode.data.originalHeight, minRequiredHeight);
      }

      const updatedContainer = {
        ...containerNode,
        data: {
          ...containerNode.data,
          height: newHeight,
        },
      };

      let adjustedContainers: any = [];
      if (isCurrentlyExpanded) {
        // Only restore positions if there's no overlap
        const containersBelow = elements
          .filter((item) => item.id.startsWith('container-'))
          .filter((item) => item.position.y > containerNode.position.y)
          .sort((a, b) => a.position.y - b.position.y);

        // Check if we can restore positions without causing overlaps
        let currentY = containerNode.position.y + newHeight;
        let canRestore = true;

        for (const container of containersBelow) {
          if (container.data.originalY < currentY) {
            canRestore = false;
            break;
          }
          currentY = container.data.originalY + container.data.height;
        }

        if (canRestore) {
          adjustedContainers = containersBelow.map((item) => ({
            ...item,
            position: {
              ...item.position,
              y: item.data.originalY,
            },
          }));
        }
      } else {
        // When expanding, move containers below down if needed
        const overlappedContainers = elements
          .filter((item) => item.id.startsWith('container-'))
          .filter((item) => item.position.y > containerNode.position.y)
          .filter((item) => item.position.y < containerNode.position.y + newHeight);

        adjustedContainers = overlappedContainers.map((item) => ({
          ...item,
          position: {
            ...item.position,
            y: item.position.y + expandedHeight,
          },
        }));
      }

      setElements([
        updatedContainer,
        ...adjustedRelatedNodes,
        ...adjustedContainers,
        ...elements
          .filter((item) => !nodesInSameColumn.includes(item))
          .filter((item) => item.id !== containerNode.id)
          .filter((item) => !adjustedContainers.some((adj: any) => adj.id === item.id))
          .filter((item) => !adjustedRelatedNodes.some((adj: any) => adj.id === item.id)),
        ...newNodes,
      ]);
    } else {
      setElements([...elements.filter((item) => !nodesInSameColumn.includes(item)), ...newNodes]);
    }
  };

  const handleCategoryAdd = (categoryId: string) => {
    // Here we find the category and add it to the graph
    setCategoriesToDraw((prev) => {
      if (!prev.includes(categoryId)) {
        return [...prev, categoryId];
      }
      return prev;
    });
  };

  const handleCategoryChipClick = (category: { text: string; value: string }) => {
    setCategoriesToDraw((prev) => {
      // If category is already selected, remove it
      if (prev.includes(category.value)) {
        return prev.filter((cat) => cat !== category.value);
      }
      // If category is not selected, add it
      return [...prev, category.value];
    });

    setSelectedCategory((prev) => {
      // If category is already selected, remove it
      if (prev.includes(category.value)) {
        return prev.filter((cat) => cat !== category.value);
      }
      // If category is not selected, add it
      return [...prev, category.value];
    });
  };

  return (
    <Box className="relative w-full h-full">
      {/* Filters and search. This guy needs to be above the rest to avoid side effects on the diagram */}
      {/* TODO: The border color is just for development */}
      <Box
        className="absolute top-2 right-2 border border-solid border-gray-500 bg-gray-100 z-50 p-2 w-96 max-w-96"
        onClick={(e) => e.stopPropagation()}>
        <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg">
          <SearchFilterProcessMap toggleFilter={toggleMenu} clickResult={handleSearchResult} />
        </div>
        <Collapse in={showMenu}>
          <>
            <Box className="flex flex-wrap items-center mt-3 text-sm">
              <Typography variant="body14semibold" className="w-full">
                {localeCommon['area'] + ': '}
              </Typography>
              <WolfDropdown
                singleSelect={true}
                items={areaList}
                label={
                  !selectedAreaId
                    ? localeProcMap['selectArea']
                    : areas.find((item) => item.areaId === selectedAreaId)?.name
                }
                initialSelectedItems={areaList.map((item) => item.value)}
                showBorder={true}
                onChange={handleAreaChange}
              />
            </Box>

            <Box className="flex flex-wrap items-center mt-3">
              <Typography variant="body14semibold" className="w-full">
                {localeCommon['service'] + ': '}
              </Typography>
              <WolfDropdown
                singleSelect={true}
                items={serviceList}
                label={
                  !selectedServiceId
                    ? localeProcMap['selectService']
                    : services.find((item) => item.serviceId === selectedServiceId)?.name
                }
                initialSelectedItems={serviceList.map((item) => item.value)}
                showBorder={true}
                onChange={handleServiceChange}
              />
            </Box>

            <Box className="flex flex-wrap items-center mt-3">
              <Typography variant="body14semibold" className="w-full mb-2">
                {localeCommon['category'] + ': '}
              </Typography>
              <Box className="flex flex-wrap gap-2">
                {categoryList.map((category) => {
                  const isSelected = categoriesToDraw.includes(category.value);
                  return (
                    <WolfChip
                      key={category.value}
                      label={category.text}
                      onClick={() => handleCategoryChipClick(category)}
                      className="cursor-pointer"
                      selected={isSelected}
                    />
                  );
                })}
              </Box>
            </Box>

            {/* Here show currently drawn categories */}
            <Box className="mt-3">
              <Typography variant="body14semibold">{localeProcMap['categoriesAdded']}</Typography>
              {drawnCategories.length === 0 && (
                <Box className="mt-2 w-100">
                  <Typography variant="body14">{localeProcMap['noCategoriesAddedYet']}</Typography>
                </Box>
              )}
              {drawnCategories.map((item) => (
                <Box key={item.id} className="my-1">
                  <DrawnCategoryItem
                    title={`${item.area} / ${item.service} / ${item.label}`}
                    onView={() => handleToggleViewCategory(item.id)}
                    onDelete={() => handleDeleteCategory(item.id)}
                  />
                </Box>
              ))}
            </Box>
          </>
        </Collapse>
      </Box>
      <div className="h-screen whitespace-break-spaces border-black border-solid">
        <ReactFlow
          key={flowKey}
          nodes={elements}
          edges={edges}
          onNodeClick={onNodeClick}
          nodeTypes={nodeTypes}
          onPaneClick={onPaneClick}
          onEdgeClick={onEdgeClick}
          fitView>
          <MiniMap />
          <Controls showInteractive={false} />
        </ReactFlow>
      </div>
    </Box>
  );
};

export default ProcessMap;
