import { ProcessRelation, ReactFlowEdge, ReactFlowNode } from '@models/process-map.model';
import { Process } from '@models/process.model';
import { MarkerType } from 'reactflow';
import { CONTAINER_PADDING, NODE_MARGIN } from './processMapConstants';

export function drawRelated(
  related: ProcessRelation[] | undefined,
  identifier: string,
  allProcesses: Process[],
  originNodes: ReactFlowNode[],
) {
  if (!related || related.length === 0) {
    return {
      nodes: [],
      edges: [],
    };
  } else {
    // Group relations by origin
    const relationsByOrigin = related.reduce(
      (acc, relation) => {
        if (!acc[relation.origin]) {
          acc[relation.origin] = [];
        }
        acc[relation.origin].push(relation);
        return acc;
      },
      {} as Record<string, ProcessRelation[]>,
    );

    let isRightmostNode = false;

    const nodes: ReactFlowNode[] = related
      .map((relation) => {
        const destinationProcess = allProcesses.find((process) => process.processId === relation.destination);
        if (!destinationProcess) return null;

        // Find the origin node
        const containerOriginNode = originNodes.find(
          (n) => n.id === originNodes.find((node) => node.id === `${identifier}-${relation.origin}`)?.parentNode,
        );
        const originNode = originNodes.find((node) => node.id === `${identifier}-${relation.origin}`);
        if (!originNode || !containerOriginNode) return null;

        // Check if there's already a node to the right of the origin
        const nodeToTheRight = originNodes.some(
          (node) =>
            Math.abs(node.position.y - originNode.position.y) < 10 &&
            node.position.x > originNode.position.x &&
            node.position.x <= originNode.position.x + 300,
        );

        // Get all relations for this origin
        const relationsForThisOrigin = relationsByOrigin[relation.origin];
        const relationIndex = relationsForThisOrigin.indexOf(relation);
        const VERTICAL_SPACING = 100;

        // Find if this is the rightmost node
        isRightmostNode = !originNodes.some((node) => node.position.x > originNode.position.x);

        // Position calculation
        const position = isRightmostNode
          ? {
              x: originNode.position.x + 500,
              y:
                containerOriginNode.position.y +
                originNode.position.y +
                (relationIndex > 0 ? relationIndex * VERTICAL_SPACING : 0),
            }
          : {
              x: originNode.position.x,
              y:
                containerOriginNode.position.y +
                originNode.position.y +
                150 +
                (relationIndex > 0 ? relationIndex * VERTICAL_SPACING : 0),
            };

        return {
          id: `${identifier}-related-${relation.destination}`,
          type: 'ProcessV2',
          position,
          data: {
            originalY: position.y,
            preLabel: destinationProcess.service?.area?.name + ' - ' + destinationProcess.service?.name,
            label: destinationProcess.name,
            color: destinationProcess.service?.color || '#F0F0F0',
            backgroundColor: destinationProcess.service?.color || '#F0F0F0',
            process: destinationProcess,
            steps: destinationProcess.steps,
            isExternal: true,
            categoryId: destinationProcess.service?.serviceId + '---' + destinationProcess.category,
            type: 'related',
          },
        };
      })
      .filter((node) => node !== null) as ReactFlowNode[];

    const edges: ReactFlowEdge[] = related.map((relation) => ({
      id: `${identifier}-related-edge-${relation.origin}-${relation.destination}`,
      source: `${identifier}-${relation.origin}`,
      target: `${identifier}-related-${relation.destination}`,
      type: 'smoothstep',
      sourceHandle: isRightmostNode ? 'right' : 'bottom',
      targetHandle: isRightmostNode ? 'left' : 'top',
      markerEnd: {
        type: MarkerType.Arrow,
        width: 30,
        height: 30,
      },
    }));

    // Here we need to check the height of the related nodes and adjust the height of the container if necessary

    const containerNode = originNodes.find((node) => node.id === 'container-' + identifier);

    if (!containerNode) return { nodes, edges };

    const contentHeight = Math.max(
      ...nodes
        .filter((node) => node.id !== 'container-' + identifier)
        .map((item) => item.position.y - containerNode.position.y),
    );

    const totalHeight = contentHeight + (CONTAINER_PADDING + NODE_MARGIN);

    const currentHeight = containerNode.data.height || 0;

    // Update container dimensions to accommodate triggers and contained nodes
    containerNode.data.height = Math.max(totalHeight, currentHeight + CONTAINER_PADDING);
    containerNode.data.originalHeight = Math.max(totalHeight, currentHeight + CONTAINER_PADDING);

    return {
      nodes,
      edges,
    };
  }
}
