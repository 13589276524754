import { ReactFlowNode } from '@models/process-map.model';
import { EXPANDED_NODE_BUFFER } from './processMapConstants';

export const calculateNextYPosition = (nodes: ReactFlowNode[], currentX: number, currentY: number): number => {
  console.log('nodes', nodes);

  // Find all nodes in the same column (similar x position, allowing for small differences)
  const nodesInSameColumn = nodes
    .filter((item) => item?.type === 'ProcessV2' || item?.type === 'TriggerNode')
    .filter((n) => Math.abs(n.position.x - currentX) < 50);

  console.log('nodesInSameColumn', nodesInSameColumn);

  if (nodesInSameColumn.length <= 1) {
    return currentY; // Fallback to default spacing if no more nodes found in the same column
  }

  // Find the lowest node in this column
  const lowestNode = nodesInSameColumn.sort((a, b) => a.position.y - b.position.y)[0];

  console.log('lowestNode', lowestNode);

  // If the current node is already the lowest, keep its position
  if (Math.abs(currentY - lowestNode.position.y) < 1) {
    return currentY;
  }

  const auxCurrNodeIndex = nodesInSameColumn.findIndex((n) => n.position.y === currentY);
  const previousNode = nodesInSameColumn[auxCurrNodeIndex - 1];
  const previousNodeHeight =
    previousNode?.type === 'CategoryContainer'
      ? 0
      : previousNode?.data?.steps?.length
        ? previousNode?.data?.steps?.length * 50
        : previousNode?.data?.triggerType
          ? previousNode?.position.y < 100
            ? 0
            : 200
          : 100;

  console.log('previousNode', previousNode);

  // Position the new node below the lowest node with some padding
  return (previousNode?.position.y || 0) + previousNodeHeight + EXPANDED_NODE_BUFFER;
};
