import { StepIcon24 } from '@components/ui/WolfIcons';
import { useTheme } from '@emotion/react';
import { StepType } from '@models/step.model';
import { AltRouteOutlined, LoopOutlined } from '@mui/icons-material';
import { Box, Paper, Typography } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';

interface WolfStepIndicatorProps {
  text: string;
  type: StepType;
  isSub: boolean;
  isProcessMap?: boolean;
}

const StepBox = styled(Paper)<{ isProcessMap?: boolean }>(({ theme, isProcessMap }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: isProcessMap ? theme.spacing(0.75) : theme.spacing(0.5),
  paddingRight: isProcessMap ? theme.spacing(0.5) : theme.spacing(1),
  // TODO: Width depending on substep or not
  width: isProcessMap ? 45 : 55,
  height: 24,
  boxSizing: 'border-box',
  justifyContent: isProcessMap ? 'center' : 'space-between',
  gap: isProcessMap ? theme.spacing(0.25) : 0,
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const getStepDetails = (type: StepType, theme: any, isSub: boolean) => {
  switch (type) {
    case StepType.PLAIN_TEXT:
      return {
        icon: <StepIcon24 width={20} height={20} />,
        bgcolor: theme.palette.utilityInfo.main,
      };
    case StepType.SWITCH:
      return {
        icon: isSub ? <StepIcon24 width={20} height={20} /> : <AltRouteOutlined width={20} height={20} />,
        bgcolor: theme.palette.utilityYellow.main,
      };
    case StepType.REPEAT:
      return {
        icon: isSub ? <StepIcon24 width={20} height={20} /> : <LoopOutlined width={20} height={20} />,
        bgcolor: theme.palette.utilityGreen.main,
      };
    default:
      return {
        icon: <StepIcon24 width={20} height={20} />,
        bgcolor: theme.palette.utilityInfo.main,
      };
  }
};

const WolfStepIndicator: React.FC<WolfStepIndicatorProps> = ({ text, type, isSub, isProcessMap = false }) => {
  const theme = useTheme();

  const stepDetails = getStepDetails(type, theme, isSub);

  return (
    <Box>
      <StepBox sx={{ backgroundColor: stepDetails.bgcolor }}>
        <IconWrapper>{stepDetails.icon}</IconWrapper>
        <Typography variant={isProcessMap ? 'body14' : 'body12'}>{text}</Typography>
      </StepBox>
    </Box>
  );
};

export default WolfStepIndicator;
