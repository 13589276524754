import { gql } from 'graphql-request';

export const ORGANIZATION_FIELDS = gql`
  fragment OrganizationFields on OrganizationDto {
    organizationId
    name
    description
    lastProfileUpdate
    onboardingDetails
    pendingActions
    status
    ownersEmails
    ownersNames
    ownersInfo
  }
`;

export const getOrganizationById = gql`
  query getOrganizationById($id: String!) {
    getOrganizationById(id: $id) {
      ...OrganizationFields
      context
    }
  }
  ${ORGANIZATION_FIELDS}
`;

export const getOrganizations = gql`
  query getOrganizations($organizations: GetOrganizationsInput!) {
    getOrganizations(organizations: $organizations) {
      organizations {
        ...OrganizationFields
      }
      count
    }
  }
  ${ORGANIZATION_FIELDS}
`;

export const createOrganization = gql`
  mutation createOrganization($organization: OrganizationCreateInput!) {
    createOrganization(organization: $organization) {
      ...OrganizationFields
    }
  }
  ${ORGANIZATION_FIELDS}
`;

// TODO: We may want to have a separate query for retrieving context and description
export const updateOrganization = gql`
  mutation updateOrganization($organization: OrganizationUpdateInput!) {
    updateOrganization(organization: $organization) {
      ...OrganizationFields
      context
      description
    }
  }
  ${ORGANIZATION_FIELDS}
`;

export const disableOrganization = gql`
  mutation disableOrganization($id: String!) {
    disableOrganization(id: $id) {
      ...OrganizationFields
    }
  }
  ${ORGANIZATION_FIELDS}
`;

export const enableOrganization = gql`
  mutation enableOrganization($id: String!) {
    enableOrganization(id: $id) {
      ...OrganizationFields
    }
  }
  ${ORGANIZATION_FIELDS}
`;

export const updatePendingActionStatus = gql`
  mutation UpdatePendingActionStatus($organizationId: String!, $actionId: String!, $status: String!) {
    updatePendingActionStatus(organizationId: $organizationId, actionId: $actionId, status: $status) {
      ...OrganizationFields
    }
  }
  ${ORGANIZATION_FIELDS}
`;

const OrganizationsGraphQL = {
  queries: {
    getOrganizationById,
    getOrganizations,
  },
  mutations: {
    createOrganization,
    updateOrganization,
    disableOrganization,
    enableOrganization,
    updatePendingActionStatus,
  },
};

export default OrganizationsGraphQL;
