import CustomModal from '@components/CustomModal';
import { AuxNodeCategoryContainerLegend } from '@components/processMapAuxV2/NodeCategoryContainer';
import { AuxNodeProcessLegend } from '@components/processMapAuxV2/NodeProcess';
import { AuxNodeTriggerLegend } from '@components/processMapAuxV2/NodeTrigger';
import { useTranslation } from '@hooks/useTranslation';
import { Box, Divider, Typography, useTheme } from '@mui/material';

interface ProcessMapLegendModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ProcessMapLegendModal: React.FC<ProcessMapLegendModalProps> = ({ isOpen, onClose }) => {
  const theme = useTheme();
  const localeCommon = useTranslation('common');

  return (
    <CustomModal isOpen={isOpen} onClose={onClose} title={localeCommon['legend']} maxWidth="1200px" maxHeight="80vh">
      <Box className="space-y-8 p-8">
        {/* Container Node */}
        <Box className="flex items-center">
          <Box className="my-2 w-full flex gap-5 justify-between">
            <Box className="w-1/2">
              <AuxNodeCategoryContainerLegend
                data={{
                  label: 'Displayed Category',
                  color: theme.palette.info.main,
                  preLabel: 'Area / Service',
                  id: 'container-example',
                }}
              />
            </Box>
            <Typography className="w-1/2">
              A Container box groups related processes together that are in the same category and is the basic unit of
              the Process Map.
            </Typography>
          </Box>
        </Box>

        <Divider />

        {/* Process Node */}
        <Box className="flex flex-wrap">
          <Box className="my-2 w-full flex gap-5 justify-between">
            <Box className="w-1/2">
              <AuxNodeProcessLegend
                data={{
                  label: 'Another Process',
                  color: theme.palette.success.main,
                }}
              />
            </Box>
            <Typography className="w-1/2">
              A Process node represents a specific task or activity within a workflow. It contains the actual steps that
              need to be performed.
            </Typography>
          </Box>
          <Box className="my-2 w-full flex gap-5 justify-between">
            <Box className="w-1/2">
              <AuxNodeProcessLegend
                data={{
                  label: 'Sample Process',
                  color: theme.palette.warning.main,
                  preLabel: 'Area - Service',
                  backgroundColor: theme.palette.warning.main,
                  isExternal: true,
                }}
              />
            </Box>
            <Typography className="w-1/2">
              "External" (processes in other categories) are displayed are linked to the flow as triggers or are
              triggered from the flow. Shaded background indicates which service they belong to.
            </Typography>
          </Box>
        </Box>

        <Divider />

        {/* Trigger Node */}
        <Box className="flex flex-wrap ">
          <Box className="my-2 w-full flex gap-5 justify-between">
            <Box className="w-1/2">
              <AuxNodeTriggerLegend
                data={{
                  label: 'Customer Email',
                  color: theme.palette.secondary.main,
                  triggerType: 'EVENT',
                }}
              />
            </Box>

            <Typography className="w-1/2">
              A Trigger node represents an event or condition that starts a process. It's the initial point that sets a
              workflow in motion.
            </Typography>
          </Box>
          <Box className="my-2 w-full flex gap-5 justify-between">
            <Box className="w-1/2">
              <AuxNodeTriggerLegend
                data={{
                  label: 'Timesheet Approval',
                  preLabel: 'Area... - Service...',
                  color: theme.palette.sectionLightBlue.main,
                  backgroundColor: theme.palette.sectionLightBlue.main,
                  triggerType: 'process',
                }}
              />
            </Box>

            <Typography className="w-1/2">
              Other processes can also be triggers, in this case the trigger will be the name of the process and be
              displayed as a trigger.
            </Typography>
          </Box>
          <Box className="my-2 w-full flex gap-5 justify-between">
            <Box className="w-1/2">
              <AuxNodeTriggerLegend
                data={{
                  label: 'No triggers for this flow',
                  color: theme.palette.secondary.main,
                  triggerType: 'warning',
                }}
              />
            </Box>

            <Typography className="w-1/2">
              Warning shown to alert you when the process does not have a clearly identified trigger.
            </Typography>
          </Box>
        </Box>
      </Box>
    </CustomModal>
  );
};

export default ProcessMapLegendModal;
