export interface Organization {
  organizationId: string;
  name: string;
  ownersNames?: string;
  ownersEmails?: string;
  ownersInfo?: string;
  status?: OrganizationStatus;
  createdAt?: Date;
  lastUpdatedAt?: Date;
  lastProfileUpdate?: Date;
  onboardingDetails?: OnboardingDetails;
  pendingActions?: any;
  description?: string;
  context?: OrganizationContext;
}

export enum OrganizationStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
}

export interface OnboardingDetails {
  ftueCompleted?: boolean;
  areaCreated?: boolean;
  serviceCreated?: boolean;
  processCreated?: boolean;
  processWritten?: boolean;
  skippedUntil?: string;
}

export interface OrganizationContext {
  mainAreas?: string[];
  troublesomeProcesses?: string[];
}

export interface getOrganizationsRequest {
  getOrganizations: {
    organizations: Organization[];
    count: number;
  };
}

export interface getOrganizationByIdRequest {
  getOrganizationById: Organization;
}

export interface createOrUpdateOrganizationRequest {
  createOrganization?: Organization;
  updateOrganization?: Organization;
}

export interface disableOrEnableOrganizationRequest {
  disableOrganization?: Organization;
  enableOrganization?: Organization;
}

export interface updatePendingActionStatusRequest {
  updatePendingActionStatus: Organization;
}
