import WolfSectionColor from '@components/ui/WolfSectionColor';
import { ExpandLess, ExpandMore, GroupWorkOutlined } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import { Handle, Position } from 'reactflow';

interface CategoryContainerNodeProps {
  data: {
    id: string;
    categoryId: string;
    label: string;
    preLabel: string;
    color: string;
    height: number;
    width: number;
  };
}

const CategoryContainerNode: React.FC<CategoryContainerNodeProps> = ({ data }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="w-full h-full p-5 bg-primaryFrozen relative">
      <Box sx={{ height: data.height, width: data.width }}>
        <Box className="flex items-center gap-3">
          <WolfSectionColor icon={<GroupWorkOutlined />} color={data.color} />
          <Typography variant="body14">{data.preLabel}</Typography>
        </Box>

        <Typography variant="body16semibold" className="!ml-10 !mt-2">
          {data.label}
        </Typography>

        <Handle type="target" position={Position.Top} style={{ visibility: 'hidden' }} />
        <Handle type="source" position={Position.Bottom} style={{ visibility: 'hidden' }} />
      </Box>

      <div
        className="absolute right-0 top-0"
        data-id={data.id}
        data-category-id={data.categoryId}
        data-action={expanded ? 'category-collapse' : 'category-expand'}
        onClick={handleExpand}>
        <IconButton className="!p-2">{expanded ? <ExpandLess /> : <ExpandMore />}</IconButton>
      </div>
    </div>
  );
};

export default CategoryContainerNode;

export const AuxNodeCategoryContainerLegend: React.FC<{
  data: {
    id: string;
    label: string;
    preLabel: string;
    color: string;
  };
}> = ({ data }) => {
  return (
    <div className="w-full h-full p-5 bg-primaryFrozen relative max-w-96">
      <Box>
        <Box className="flex items-center gap-3">
          <WolfSectionColor icon={<GroupWorkOutlined />} color={data.color} />
          <Typography variant="body14">{data.preLabel}</Typography>
        </Box>

        <Typography variant="body16semibold" className="">
          {data.label}
        </Typography>
      </Box>
    </div>
  );
};
